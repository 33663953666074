<template>
  <div class="value-and-quantity">
    <div
      class="list-detail-container d-flex flex-column scroll-container white rounded-md"
      v-if="allItemsList"
    >
      <h2 class="primary--text font-weight-bold pl-3 pl-sm-0 py-3 py-sm-0">
        Seleziona il taglio e la quantità
      </h2>

      <div v-if="allItemsList.length > 0">
        <v-list v-if="allItemsList">
          <ListItemCustom
            v-for="item in allItemsList"
            v-bind:key="item.product.id"
            :item="item"
            :isActive="true"
            :smartCart="smartCart"
            @removeItem="removeFromList"
          ></ListItemCustom>
        </v-list>
      </div>

      <v-alert
        type="info"
        v-if="smartCart.totalPrice && smartCart.totalPrice > 0"
        >{{ $t(response.info) }}
        <strong>{{ $n(smartCart.totalPrice, "currency") }}</strong>
      </v-alert>
    </div>
    <h4 style="color: red;">
      <v-row>
        <v-col cols="2" class="d-flex align-center justify-center">
          <img
            class="mr-2"
            width="60"
            src="/img_layout/melaregalo/shopping-bag.png"
          />
        </v-col>
        <v-col cols="10" class="  " style="margin-top:15px;">
          Per ogni ordine pari o superiore a
          <strong>1.500€</strong>, si riconosce il 3% del valore in Melaregalo
          aggiuntive.
        </v-col>
      </v-row>
    </h4>

    <div
      class="custom-text list-detail-container d-flex flex-column scroll-container white rounded-md"
    >
      <h2>
        Tipologia Melaregalo
      </h2>
      <div>
        <v-radio-group class="ml-1" dense v-model="isFisicalCard" row>
          <v-radio color="primary" :value="true">
            <template v-slot:label>
              <span class="distance-label distance-radio-label">
                Fisica
              </span>
            </template>
          </v-radio>

          <br />
          <v-radio color="primary" :value="false">
            <template v-slot:label>
              <span class="distance-label distance-radio-label">
                Digitale
              </span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </div>
    <div
      class="custom-text list-detail-container d-flex flex-column scroll-container white rounded-md"
    >
      <h2>
        Desideri una personalizzazione?
      </h2>
      <div>
        <span
          >E' possibile personalizzare gratuitamente le Melaregalo con il logo
          dell'Azienda e/o con il nome del dipendente.</span
        >
        <br />
        <span>
          La personalizzazione comparirà sul fronte della carta.
        </span>

        <v-radio-group class="ml-1" dense v-model="customizeCard" row>
          <v-radio color="primary" :value="true">
            <template v-slot:label>
              <span class="distance-label distance-radio-label">
                Si
              </span>
            </template>
          </v-radio>

          <br />
          <v-radio color="primary" :value="false">
            <template v-slot:label>
              <span class="distance-label distance-radio-label">
                No
              </span>
            </template>
          </v-radio>
        </v-radio-group>

        <span>
          Scarica il file Excel e segui le indicazioni in esso contenute.
          Ricarica poi il file compilato.<br />
          Inviare il logo a
          <a href="mailto:melaregalo@tigros.it">melaregalo@tigros.it</a>
        </span>
        <br />
        <br />

        <v-row>
          <v-col cols="12" md="3">
            <v-btn
              icon
              x-large
              block
              target="blank"
              :href="`${urlDocument}`"
              @click="onLinkClick"
              v-show="customizeCard"
            >
              <div style=" background-color:#ffdd02 ; padding: 7px;">
                <v-icon>icon-scarica</v-icon>
                {{ $t("SCARICA FILE") }}
              </div>
            </v-btn>
          </v-col>

          <v-col cols="12" md="8">
            <v-file-input
              ref="uploader"
              outlined
              background-color="white"
              placeholder="Nessun file selezionato"
              v-model="attachmentModel"
              dense
              prepend-icon="mdi-cloud-upload"
              id="uploader"
              accept="xls"
              :label="
                documentLoaded ? 'DOCUMENTO CARICATO' : 'CARICA FILE COMPILATO'
              "
              v-show="customizeCard"
              :disabled="documentLoaded"
            ></v-file-input>
            <p
              v-show="customizeCard"
              color="$text-color"
              style="margin-left: 30px;margin-top: -20px;font-size: 13px; opacity: 0.6;"
            >
              Dimensione massima: 5MB <br />(formati: xls)
            </p>
          </v-col>
        </v-row>
      </div>
      <v-alert type="error" v-if="imgErrorString"
        >Attenzione: il file è troppo grande; caricare un file di dimensioni
        minori.
      </v-alert>
    </div>
  </div>
</template>
<style lang="scss">
.value-and-quantity {
  .row {
    // border-bottom: 1px solid var(--v-grey-lighten2);
    .label .price {
      font-size: 16px;
    }
  }

  .custom-text {
    color: var(--v-primary-base) !important;
  }
}
</style>
<script>
import ListService from "~/service/listService";
import ListItemCustom from "@/components/melaregalo/ListItemCustom.vue";
import clickHandler from "~/mixins/clickHandler";
import downloadDocument from "~/mixins/downloadDocument";
import concat from "lodash/concat";
import tigrosCustomService from "@/service/tigrosCustomService";

export default {
  name: "ValueAndQuantity",
  props: {
    allItemsList: { type: Array, required: true },
    smartCart: { type: Object, required: true },
    documentLoaded: { type: Boolean, required: true }
  },
  components: {
    ListItemCustom
  },
  data() {
    return {
      loadingUpdate: false,
      customizeCard: false,
      isFisicalCard: true,
      imgErrorString: false,
      attachmentModel: null,
      tmpList: { type: Array, required: true },
      response: {
        info: "Hai selezionato MelaRegalo per un importo totale di "
      },
      urlDocument:
        "https://www.tigros.it/photo2/category/148032236/generic/raw/MODULO%20PERSONALIZZAZIONE%20MELAREGALO.xlsx"
    };
  },
  mixins: [clickHandler, downloadDocument],

  methods: {
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      //this.$refs.uploader.$el.click();
      document.getElementById("uploader").click();
    },
    hasFileSelected() {
      let data = this.attachmentModel && this.attachmentModel.length > 0;
      if (!data) {
        data = false;
      }
      global.EventBus.$emit("hasFileSelected", data);
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async handleUserDataSubmit(e) {
      try {
        var _this = this;
        console.log(_this);
        e.preventDefault();
        _this.loadingUpdate = true;
        var fileToUpload = {};

        //file deve essere minore di 2mb
        if (_this.attachmentModel && _this.attachmentModel.size / 1024 > 5120) {
          _this.imgErrorString = true;
        } else {
          _this.imgErrorString = false;

          if (_this.attachmentModel) {
            fileToUpload.contentText = await _this.toBase64(
              _this.attachmentModel
            );

            fileToUpload.fileName = _this.attachmentModel.name;
            fileToUpload.mimeType = _this.attachmentModel.type;
          }
          let res = await tigrosCustomService.setCartInfo(
            "WISHLIST_INFO",
            fileToUpload.contentText,
            true
          );

          if (res && res.status == 0) {
            _this.hasFileSelected(true);
          } else {
            _this.hasFileSelected(false);
          }
        }
      } catch (r) {
        console.log(r);
        _this.response = r;
      } finally {
        _this.loadingUpdate = false;
      }
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".xls")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else {
          this.clicked(e);
        }
      });
    },
    async removeFromList({ itemId, product }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          this.list,
          itemId,
          product.productId
        );
        this.list = newList;
        this.setItemGroups(
          concat(this.list.wishlistInactiveItems, this.list.wishlistItems)
        );
      }
    }
    // async updateList({ newQuantity, item }) {
    //   const res = await ListService.updateProductsInList(
    //     this.listId,
    //     item,
    //     newQuantity
    //   );
    //   this.list = res;
    // }
  },
  created() {
    //console.log(this.tmpList);
    // console.log(this.props.allItemsList);
    global.EventBus.$on("submitDocument", () => {
      this.handleUserDataSubmit(event);
    });
    tigrosCustomService.setCartInfo(
      "is_melaregalo_fisicalcard",
      true,
      undefined,
      7
    );
  },
  watch: {
    listId() {
      console.log(this.allItemsList);
      console.log(this.props.allItemsList);
    },
    attachmentModel(newValue, oldValue) {
      if (oldValue != newValue) {
        let data = newValue != null;
        if (!data) {
          data = false;
        } else {
          data = true;
        }
        global.EventBus.$emit("hasFileSelected", data);
      }
    },
    customizeCard(newValue, oldValue) {
      if (newValue != oldValue) {
        global.EventBus.$emit("customizeCard", newValue);
      }
    },
    isFisicalCard(newValue, oldValue) {
      if (newValue != oldValue) {
        global.EventBus.$emit("isFisicalCard", newValue);
        tigrosCustomService.setCartInfo(
          "is_melaregalo_fisicalcard",
          newValue,
          undefined,
          7
        );
      }
    }
  }
};
</script>
